import React, { useEffect, useRef, useState } from 'react';
import { Block } from 'baseui/block';
import * as pose from '@mediapipe/pose';
import * as drawingUtils from '@mediapipe/drawing_utils';
import * as cam from '@mediapipe/camera_utils';

function PushupTracker({ onLandmarksDetected, selectedCamera, facingMode }) {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [mpPose, setMpPose] = useState(null);
  const [camera, setCamera] = useState(null);
  const [isCameraReady, setIsCameraReady] = useState(false);

  
  // Initialize the Pose model
  useEffect(() => {
    const initializePose = () => {
      if (mpPose) {
        mpPose.close();
      }
      const localMpPose = new pose.Pose({
        locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
      });

      localMpPose.setOptions({
        selfieMode: facingMode === 'user',
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: false,
        minDetectionConfidence: 0.9,
        minTrackingConfidence: 0.9,
      });

      localMpPose.onResults(onResults);
      setMpPose(localMpPose);
    };

    initializePose();

    return () => {
      if (mpPose) {
        mpPose.close();
      }
    };
  }, [facingMode]);

  // Switch camera or initialize camera with selected device ID
  useEffect(() => {
    if (!mpPose) return;

    // Stop the previous camera and close all media tracks
    const stopPreviousCamera = () => {
      if (camera) {
        camera.stop();
      }
      if (webcamRef.current && webcamRef.current.srcObject) {
        webcamRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };

    const initializeCamera = async (constraints) => {
      stopPreviousCamera();

      try {
        console.log('Initializing camera with constraints:', constraints);
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        webcamRef.current.srcObject = stream;

        const newCamera = new cam.Camera(webcamRef.current, {
          onFrame: async () => {
            if (mpPose && webcamRef.current && webcamRef.current.srcObject) {
              await mpPose.send({ image: webcamRef.current });
            }
          },
          width: 1280,
          height: 720,
        });

        newCamera.start();
        setCamera(newCamera);
        setIsCameraReady(true);
       } catch (error) {
        if (error.name === 'OverconstrainedError') {
          console.error('OverconstrainedError:', error.constraint);
          if (constraints.video.facingMode) {
            console.log('Retrying without facingMode constraint');
            initializeCamera({
              video: {
                deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
                width: 1280,
                height: 720,
              },
              audio: false,
            });
          }
        } else {
          console.error('Error initializing camera:', error);
        }
      }
    };

    const constraints = {
      video: {
        deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
        facingMode: facingMode ? { exact: facingMode } : undefined,
        width: 1280,
        height: 720,
      },
      audio: false,
    };

    initializeCamera(constraints);

    return () => {
      stopPreviousCamera();
    };
  }, [mpPose, selectedCamera, facingMode]);

  // Process pose results and draw on the canvas
  function onResults(results) {
    if (!canvasRef.current) return;

    const canvasElement = canvasRef.current;
    if (canvasElement) {
      const canvasCtx = canvasElement.getContext('2d');
      canvasElement.width = 1280;
      canvasElement.height = 720;
      canvasCtx.clearRect(0, 0, 1280, 720);
      canvasCtx.drawImage(results.image, 0, 0, 1280, 720);

      if (results.poseLandmarks) {
        drawingUtils.drawConnectors(canvasCtx, results.poseLandmarks, pose.POSE_CONNECTIONS, {
          color: 'white',
          lineWidth: 2,
        });
        drawingUtils.drawLandmarks(canvasCtx, results.poseLandmarks, {
          color: 'white',
          fillColor: 'rgb(255,138,0)',
          radius: (data) => (data.visibility < 0.65 ? 0 : 3),
        });
        onLandmarksDetected(results.poseLandmarks);
      }
    }
  }

  return (
    <Block
      overrides={{
        Block: {
          style: {
            width: '100%',
            maxWidth: '1280px',
            height: 'auto',
            aspectRatio: '16 / 9',
          },
        },
      }}
    >
      <video ref={webcamRef} style={{ display: 'none' }} playsInline autoPlay muted loop />
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }}></canvas>
    </Block>
  );
}

export default PushupTracker;
