import React, { useEffect, useState } from 'react';
import { Block } from 'baseui/block';
import CommonNavBar from './NavBar';
import { useStyletron } from 'baseui';
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid';
import { db } from '../firebase'; // Make sure this points to your Firebase configuration
import { collection, getDocs, query, orderBy } from 'firebase/firestore';

function Leaderboard() {
  const [css, theme] = useStyletron();
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Use the correct date format for your database
    const q = query(collection(db, 'pushupCounts'), orderBy(today, 'desc'));
    console.log(q);
    console.log(today);
    const fetchLeaderboardData = async () => {
      try {
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach(doc => {
          // Assuming the document structure has a field with today's date and contains pushup counts
          const docData = doc.data();
          console.log(docData);
          if (docData[today]) {
            data.push([doc.id, docData.name, docData[today]]); // Change fields as necessary
          }
        });
        console.log(data);
        setLeaderboardData(data);
      } catch (error) {
        console.error('Error fetching leaderboard data: ', error);
      }
    };

    fetchLeaderboardData();
  }, []);

  return (
    <Block>
      <CommonNavBar />
      <Block padding="scale600" className={css({ maxWidth: '800px', margin: '0 auto' })}>
        <h1>Daily Leaderboard</h1>
        <p>Most pushups completed today.</p>
        <StyledTable>
          <Block className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            width: '100%',
            position: 'relative',
            backgroundColor: theme.colors.backgroundPrimary,
          })}>
            <StyledHeadCell>Rank</StyledHeadCell>
            <StyledHeadCell>Name</StyledHeadCell>
            <StyledHeadCell>Pushups</StyledHeadCell>
          </Block>
          {leaderboardData.map((row, index) => (
            <Block className={css({
              display: 'grid',
              position: 'relative',
              gridTemplateColumns: 'repeat(3, 1fr)',
              width: '100%',
              borderBottom: `1px solid ${theme.colors.borderOpaque}`,
              padding: theme.sizing.scale300,
            })} key={index}>
              <StyledBodyCell>{index + 1}</StyledBodyCell>
              <StyledBodyCell>{row[1]}</StyledBodyCell>
              <StyledBodyCell>{row[2]}</StyledBodyCell>
            </Block>
          ))}
        </StyledTable>
      </Block>
    </Block>
  );
}

export default Leaderboard;
