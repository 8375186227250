import React, { useState, useEffect } from 'react';
import { Select, Option } from 'baseui/select';
import { Radio, RadioGroup } from 'baseui/radio';

function CameraSelection({ onCameraChange, onFacingModeChange }) {
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [facingMode, setFacingMode] = useState('user');

  useEffect(() => {
    async function fetchCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0].deviceId);
        }
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    }
    fetchCameras();
  }, []);

  useEffect(() => {
    onCameraChange(selectedCamera);
  }, [selectedCamera]);

  useEffect(() => {
    onFacingModeChange(facingMode);
  }, [facingMode]);

  return (
    <div>
      <Select
        options={cameras.map(camera => ({ id: camera.deviceId, label: camera.label }))}
        value={cameras.find(camera => camera.deviceId === selectedCamera)}
        onChange={({ value }) => setSelectedCamera(value[0].id)}
        placeholder="Select Camera"
      />
      <RadioGroup
        value={facingMode}
        onChange={e => setFacingMode(e.target.value)}
      >
        <Radio value="user">Selfie</Radio>
        <Radio value="environment">Environment</Radio>
      </RadioGroup>
    </div>
  );
}

export default CameraSelection;
