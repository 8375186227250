// import React, { useRef, useEffect } from 'react';

// function CameraFeed({ selectedCamera, facingMode, onCameraReady }) {
//   const webcamRef = useRef(null);

//   useEffect(() => {
//     const initializeCamera = async () => {
//       let constraints = {
//         video: {
//           width: 1280,
//           height: 720,
//           deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
//           facingMode: facingMode ? { exact: facingMode } : undefined,
//         },
//         audio: false,
//       };

//       try {
//         const stream = await navigator.mediaDevices.getUserMedia(constraints);
//         if (webcamRef.current) {
//           webcamRef.current.srcObject = stream;
//         }
//         onCameraReady(webcamRef.current);
//       } catch (error) {
//         if (error.name === 'OverconstrainedError') {
//           console.warn('OverconstrainedError:', error.constraint);

//           // Fallback: Remove the facingMode constraint and try again
//           try {
//             constraints = {
//               video: {
//                 width: 1280,
//                 height: 720,
//                 deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
//               },
//               audio: false,
//             };
//             const stream = await navigator.mediaDevices.getUserMedia(constraints);
//             if (webcamRef.current) {
//               webcamRef.current.srcObject = stream;
//             }
//             onCameraReady(webcamRef.current);
//           } catch (innerError) {
//             console.error('Failed to get user media without facingMode constraint:', innerError);
//           }
//         } else {
//           console.error('Error initializing camera:', error);
//         }
//       }
//     };

//     initializeCamera();
//   }, [selectedCamera, facingMode]);

//   // Keep video in the DOM but hidden using CSS technique
//   return (
//     <video 
//       ref={webcamRef} 
//       style={{ 
//         position: 'absolute', 
//         top: '0px', 
//         left: '0px', 
//         width: '1px',  // Adjust to a reasonable size for debugging
//         height: '1px', // Adjust to a reasonable size for debugging
//         zIndex: 1000     // Ensure it appears above other elements
//       }} 
//       playsInline 
//       autoPlay 
//       muted 
//     />
//   );
// }

// export default CameraFeed;
import React, { useRef, useEffect } from 'react';

function CameraFeed({ selectedCamera, facingMode, onCameraReady }) {
  const webcamRef = useRef(null);

  useEffect(() => {
    const initializeCamera = async () => {
      let constraints = {
        video: {
          width: 1280,
          height: 720,
          deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
          facingMode: facingMode ? { exact: facingMode } : undefined,
        },
        audio: false,
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (webcamRef.current) {
          webcamRef.current.srcObject = stream;
        }
        onCameraReady(webcamRef.current);
      } catch (error) {
        if (error.name === 'OverconstrainedError') {
          console.warn('OverconstrainedError:', error.constraint);

          try {
            constraints = {
              video: {
                width: 1280,
                height: 720,
                deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
              },
              audio: false,
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (webcamRef.current) {
              webcamRef.current.srcObject = stream;
            }
            onCameraReady(webcamRef.current);
          } catch (innerError) {
            console.error('Failed to get user media without facingMode constraint:', innerError);
          }
        } else {
          console.error('Error initializing camera:', error);
        }
      }
    };

    initializeCamera();
  }, [selectedCamera, facingMode]);

  return (
    <video 
      ref={webcamRef} 
      style={{ 
        // position: 'fixed', // Keep the video in a fixed position but offscreen
        top: '-9999px', 
        left: '-9999px', 
        width: '1px', 
        height: '1px',
      }} 
      playsInline 
      autoPlay 
      muted 
    />
  );
}

export default CameraFeed;
