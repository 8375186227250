import React from "react";
import { AppNavBar } from "baseui/app-nav-bar";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

function CommonNavBar() {
  const navigate = useNavigate();

  const mainItems = [
    { label: "Leaderboard", path: "/profile" },
    { label: "PushupCounter", path: "/pushupcounter" },
    // { label: "Settings", path: "/settings" },
  ];

  const handleMainItemSelect = (item) => {
    if (item.path) {
      navigate(item.path);
    }
  };

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const goToSettings = () => {
    navigate("/settings");
  }

  const userItems = [{ label: "Logout", onClick: logout },  { label: "Settings", onClick: goToSettings },];

  const handleUserItemSelect = (item) => {
    console.log(item);
    if (item && item.onClick) {
      item.onClick();
    } else {
      console.error("Selected item does not have an onClick function");
    }
  };

  return (
    <AppNavBar
      title="Pushup Counter"
      mainItems={mainItems}
      onMainItemSelect={handleMainItemSelect}
      userItems={userItems}
      onUserItemSelect={handleUserItemSelect}
      username={auth.currentUser ? auth.currentUser.email : "User"}
      overrides={{
        PrimaryMenuContainer: {
          style: {
            justifyContent: "left",
          },
        },
        AppName: {
          style: {
            marginRight: "50px",
          },
        },
      }}
    />
  );
}

export default CommonNavBar;
